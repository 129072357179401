import "core-js/modules/es.array.concat";
import request from '@/utils/request';
export var defaultOrganization = {
  id: 0,
  name: '',
  type: '',
  clinicCode: '',
  useYn: true,
  createdTime: '',
  updatedTime: ''
};
export var getOrganizationList = function getOrganizationList() {
  return request({
    url: '/user/api/v1/organization',
    method: 'get'
  });
};
export var updateOrganization = function updateOrganization(id, data) {
  return request({
    url: "/user/api/v1/organization/".concat(id),
    method: 'put',
    data: data
  });
};
export var createOrganization = function createOrganization(data) {
  return request({
    url: '/user/api/v1/organization',
    method: 'post',
    data: data
  });
};
export var getTherapistListByOrg = function getTherapistListByOrg(id, params) {
  return request({
    url: "/user/api/v1/organization/".concat(id, "/therapist"),
    method: 'get',
    params: params
  });
};
export var toRoleTypeOwner = function toRoleTypeOwner(id, userId) {
  return request({
    url: "/user/api/v1/organization/".concat(id, "/therapist/").concat(userId, "/addOwnerRole"),
    method: 'post'
  });
};
export var toRoleTypeNormal = function toRoleTypeNormal(id, userId) {
  return request({
    url: "/user/api/v1/organization/".concat(id, "/therapist/").concat(userId, "/deleteOwnerRole"),
    method: 'delete'
  });
};